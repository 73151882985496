<template>
  <div>
    <h1>Hello virtual-private-server</h1>
  </div>
</template>

<script>
export default {
  name: "VirtualPrivateServer",
};
</script>

<style lang="sass" scoped></style>
